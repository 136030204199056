import { Component } from 'react';

import { withdraw } from 'api';

class WithdrawHandler extends Component {

  async componentDidMount() {
    const ticket = window.location.search.substring(8);
    const withdrawResult = await withdraw(ticket);

    // success
    if ('header' in withdrawResult && withdrawResult.header.isSuccessful) {
      window.opener.sessionStorage.clear();
      alert('탈퇴가 완료되었습니다.');
    }
    // failure
    else {
      alert('예기치 않은 문제가 발생하였습니다.');
    }

    window.opener.location.replace('/');
    window.close();
  }

  render() {
    return null;
  }
}

export default WithdrawHandler;